<template>
  <div>
    <paginate
        v-if="list.length"
        v-model="currentPage"
        :page-count="pagesLength"
        :click-handler="changePage"
        containerClass="pagination"
        container-class="pagination"
    >
    </paginate>
  </div>
</template>

<script>
export default {
  name: 'custom-pagination',
  props: ['list', 'pagesLength', 'changePage', 'page'],
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set() {
        return this.page
      }
    }
  }
}
</script>

<style lang="scss">

a {
  color: black !important;
}

.pagination {
  width: fit-content;
  margin: 41px auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDD0D9;
  box-sizing: border-box;
  border-radius: 6px;
  padding-left: 0px;
}

.active {
  background: #EC8B31;
  border: 1px solid #EC8B31;
  border-radius: 3px;

  a {
    color: white !important;
  }
}

.pagination li {
  width: 51px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  border-right: 1px solid #CDD0D9;

  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &:first-child, &:last-child {
    display: none;
    border-right: unset;
  }

  &:nth-last-child(2) {
    border-right: unset;
  }
}
</style>