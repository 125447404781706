<template>
  <div style="padding: 0 35px 0 21px;" class="log-list-wrap">
    <p class="logs"><b>{{ $t('logs') }}</b></p>

<!--    <div class="search-actions-wrap">-->
<!--      <div class="search">-->
<!--        <div class="sign-event filter-status-wrap filter-date-wrap md-layout-item">-->
<!--          <md-datepicker>-->
<!--            <label>From date</label>-->
<!--            <label></label>-->
<!--          </md-datepicker>-->
<!--        </div>-->
<!--        <div class="sign-event filter-status-wrap filter-date-wrap md-layout-item">-->
<!--          <md-datepicker  >-->
<!--            <label>To date</label>-->
<!--            <label></label>-->
<!--          </md-datepicker>-->
<!--        </div>-->
<!--        <div class="md-layout-item">-->
<!--          <md-field>-->
<!--            <md-select-->
<!--                name="status"-->
<!--                class="select-status"-->
<!--            > <md-option value="" disabled selected hidden>Please Choose...</md-option>-->
<!--            </md-select>-->
<!--          </md-field>-->
<!--        </div>-->
<!--        <div class="md-layout-item">-->
<!--          <md-field>-->
<!--            <md-select-->
<!--                name="status"-->
<!--                class="select-status"-->
<!--            > <md-option value="" disabled selected hidden>Please Choose...</md-option>-->
<!--            </md-select>-->
<!--          </md-field>-->
<!--        </div>-->
<!--        <div class="md-layout-item">-->
<!--          <md-field>-->
<!--            <md-select-->
<!--                name="status"-->
<!--                class="select-status"-->
<!--            > <md-option value="" disabled selected hidden>Please Choose...</md-option>-->
<!--            </md-select>-->
<!--          </md-field>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <md-table>
      <md-table-row md-selectable="multiple" class="head_row">
        <md-table-head
            v-for="(item, i) in $t('headData')"
            :key="i"
        >
          <md-ripple class="md-table-title">
            <div>
              {{ item.title }}
            </div>
          </md-ripple>
        </md-table-head>
      </md-table-row>

      <md-table-row v-for="log in logs" :key="log.id">
        <md-table-cell>{{ log.date }}</md-table-cell>
        <md-table-cell>{{ log.subject_user}}</md-table-cell>
        <md-table-cell>{{ log.action_type }}</md-table-cell>
        <md-table-cell>{{ log.object_type }}</md-table-cell>
        <md-table-cell>{{ log.object_instance}}</md-table-cell>
      </md-table-row>
    </md-table>
    <custom-pagination
        :list="logs"
        :page="pagination.current_page"
        :pagesLength="pagesLength"
        :changePage="changePage"
    />
  </div>
</template>

<script>
import CustomPagination from "../../../../tkm-frontend/src/components/pagination/CustomPagination";
import {mapActions, mapGetters,mapState} from "vuex";

export default {
  name: 'Logs',
  components: {CustomPagination},
  data: () => ({
    logs: [],
    pagination: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      total: null
    },
    search: '',
    status: '',
  }),
  computed: {
    ...mapGetters(['reqPagination','logsList']),
    ...mapState(['user']),
    pagesLength() {
      return Math.ceil(+this.pagination.total / +this.pagination.per_page)
    },

  },

  async mounted() {
    let currentPage = this.$route.query.page ? this.$route.query.page : 1
    await this.getPaginatedList({page: currentPage})
  },

  methods: {
    ...mapActions(['getLogsList']),
    getPaginatedList({page = 1, email = '', role = '', status = '', sort = '', sortBy = 'desc'}) {
      this.getLogsList({page, email, role, status, sort, sortBy})
          .then(data => {
            this.logs = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
    },
    changePage(currentPage) {
      this.getPaginatedList({
        page: currentPage,
        email: this.search,
        role: this.search,
        status: this.status,
        sort: this.sortName,
        sortBy: this.sort
      })
      this.$router.push({name: this.$route.name, query: {page: currentPage}})
    },
  }
}
</script>

<style lang="scss">
.log-list-wrap {

  .md-input {
    padding-left: 50px;
    position: absolute;
    top: 8px;

  }
  .md-table-row{
    cursor: pointer;
  }

  .logs {
    margin: 0;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
  }
  .md-datepicker {
    align-items: center;

    .md-icon {
      margin-left: 18px;
      left: 0;
    }

    svg {
      fill: #054F63 !important;
    }
    .md-input {
      padding: 0!important;
      margin-left: 50px!important;
    }
  }
  .filter-date-wrap{

    .md-input-action{
      top: 8px;
      right: 6px;
    }
    .md-button{
      border: none;
    }
    .md-field>.md-icon~label {
      left: 56px;
      top: 14px;
    }
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .actions {
    display: flex;
  }

  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary .md-icon-font, .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: #7f7878 !important;
    background-color: white;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: white;
  }

  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 158px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 9px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-right: 15px;
    }
  }

  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 158px;
    height: 48px;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header{
    height: unset!important;
    max-height: unset!important;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .search-actions-wrap {
    display: flex;
    justify-content: space-between;
  }

  .search {
    display: flex;

  }

  .md-layout-item {
    flex: unset;
    height: 48px;
    margin-right: 20px;
  }
  .select-status {
    width: 250px;
    height: 48px;
    border-radius: 4px;
    outline: none;
  }
  .select-status>input{
    padding-left: 20px;
    font-size: 14px;
  }

  .md-field {
    width: 265px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 0;
    padding-top: unset;

    &::after {
      background-color: unset !important;
    }
  }

  .md-input {
    height: unset !important;
  }

  .md-select {
    .md-icon-image {
      position: absolute;
      right: 0;
      left: 75%;
      top: 13px;
    }
  }

  .search-select {
    position: absolute;
    left: 15px;
    top: 14px;
  }

  .md-ripple, .md-table-cell-container {
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;;
  }

  .md-checkbox .md-checkbox-container {
    width: 18px !important;
    min-width: 18px !important;
    height: 18px !important;
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }


  .md-table-title > div {
    color: #000000 !important;
    font-size: 18px;
    font-weight: bold;
  }

  .arrow_down:hover {
    cursor: pointer;
  }

  .md-table-title:hover {
    cursor: pointer;
  }

  .arrow_down {
    height: 18px !important;
    overflow: auto;
    transition: 0.25s ease;
  }

  .head_row {
    border-bottom: 1.5px solid #000000 !important;
  }

  .md-checkbox {
    margin: 8px 0 0 20px;
    display: inline-flex;
    position: relative;
    height: 18px;
    width: 18px;
    border: unset !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    &:last-child .md-table-cell-container {
      display: flex;
    }
  }
  .md-table-head-container {
    padding-top: 10px !important;
    padding: unset;
  }

  .md-table-head-label {
    font-weight: bold;
  }
  .md-table-head:last-child {
    .md-table-head-label {
      padding-left: 42px!important;
    }
  }

}
</style>
<style lang="scss">
.log-list-wrap {
  .md-table-head-label {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    padding-right: unset !important;
    padding-left: 22px !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>